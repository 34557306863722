.button {
  all: unset;
  align-items: center;
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.button:hover {
  cursor: pointer;
  transition: .2s ease-in-out;
  opacity: 0.8;
}

.button .content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.button .text-wrapper {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  font-family: 'Inter', sans-serif !important;
}

.button .icon-outlined {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.button .div {
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button.state-loading {
  opacity: 0.65;
}

.button.default {
  border: 1px solid;
}

.button.dashed {
  border: 1px dashed;
}

.button.state-pressed.dashed {
  border-color: var(--purplepurple-700);
}

.button.state-default.primary {
  background-color: #7041F1;
}

html[data-theme="dark"] .button.state-default.primary {
  background-color: #9471F4;
}

.button.text.state-pressed {
  background-color: var(--slateslate-50);
}

.button.text.small {
  padding: 1px 8px;
}

.button.state-loading.primary {
  background-color: var(--purplepurple-400);
}

.button.large.primary {
  padding: 8px 16px;
}

.button.small.dashed {
  padding: 1px 8px;
}

.button.state-hover.primary {
  background-color: var(--purplepurple-500);
}

.button.state-focused.primary {
  background-color: var(--purplepurple-600);
}

.button.text.default {
  padding: 5px 16px;
}

.button.dashed.state-disabled {
  background-color: var(--slateslate-50);
  border-color: var(--slateslate-50);
}

.button.default.primary {
  padding: 5px 16px;
}

.button.state-hover.default {
  border-color: var(--purplepurple-500);
}

.button.text.state-focused {
  background-color: var(--slateslate-100);
}

.button.default.large {
  padding: 8px 16px;
}

.button.default.state-loading {
  background-color: var(--slateslate-50);
  border-color: var(--slateslate-50);
}

.button.default.state-default {
  border-color: var(--slateslate-50);
}

.button.state-pressed.primary {
  background-color: var(--purplepurple-700);
}

.button.state-pressed.default {
  border-color: var(--purplepurple-700);
}

.button.state-default.dashed {
  border-color: var(--slateslate-50);
}

.button.text.state-hover {
  background-color: var(--slateslate-100);
}

.button.dashed.large {
  padding: 8px 16px;
}

.button.dashed.state-loading {
  background-color: var(--slateslate-50);
  border-color: var(--slateslate-50);
}

.button.state-hover.dashed {
  border-color: var(--purplepurple-500);
}

.button.small.primary {
  padding: 1px 8px;
}

.button.state-focused.dashed {
  border-color: var(--purplepurple-600);
}

.button.default.default {
  padding: 5px 16px;
}

.button.state-disabled.primary {
  background-color: var(--slateslate-50);
  border: 1px solid;
  border-color: var(--slateslate-50);
}

.button.default.state-disabled {
  background-color: var(--slateslate-50);
  border-color: var(--slateslate-50);
}

.button.default.dashed {
  padding: 5px 16px;
}

.button.state-focused.default {
  border-color: var(--purplepurple-600);
}

.button.text.large {
  padding: 8px 16px;
}

.button.default.small {
  padding: 1px 8px;
}

.button.state-loading .text-wrapper {
  color: var(--purplepurple-500);
}

.button.state-disabled .text-wrapper {
  color: var(--slateslate-400);
}

.button.link .text-wrapper {
  font-family: "Roboto-Regular", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  text-decoration: underline;
}

.button.state-focused.link .text-wrapper {
  color: var(--purplepurple-500);
}

.button.state-pressed.dashed .text-wrapper {
  color: var(--purplepurple-700);
}

.button.state-default.primary .text-wrapper {
  color: var(--slateslate-50);
}

html[data-theme="dark"] .button.state-default.primary .text-wrapper {
  color: #111624;
}

.button.small.link .text-wrapper {
  font-size: 14px;
  line-height: 22px;
}

.button.text.state-pressed .text-wrapper {
  color: var(--slateslate-800);
}

.button.state-pressed.link .text-wrapper {
  color: var(--purplepurple-700);
}

.button.default.link .text-wrapper {
  font-size: 14px;
  line-height: 22px;
}

.button.text.small .text-wrapper {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.large.primary .text-wrapper {
  font-family: var(--regular-16px-24px-font-family);
  font-size: var(--regular-16px-24px-font-size);
  font-style: var(--regular-16px-24px-font-style);
  font-weight: var(--regular-16px-24px-font-weight);
  letter-spacing: var(--regular-16px-24px-letter-spacing);
  line-height: var(--regular-16px-24px-line-height);
}

.button.small.dashed .text-wrapper {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.state-hover.primary .text-wrapper {
  color: var(--slateslate-50);
}

.button.state-focused.primary .text-wrapper {
  color: var(--slateslate-50);
}

.button.text.default .text-wrapper {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.default.primary .text-wrapper {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.state-hover.default .text-wrapper {
  color: var(--purplepurple-500);
}

.button.text.state-focused .text-wrapper {
  color: var(--slateslate-800);
}

.button.default.large .text-wrapper {
  font-family: var(--regular-16px-24px-font-family);
  font-size: var(--regular-16px-24px-font-size);
  font-style: var(--regular-16px-24px-font-style);
  font-weight: var(--regular-16px-24px-font-weight);
  letter-spacing: var(--regular-16px-24px-letter-spacing);
  line-height: var(--regular-16px-24px-line-height);
}

.button.default.state-default .text-wrapper {
  color: var(--purplepurple-500);
}

.button.state-pressed.primary .text-wrapper {
  color: var(--slateslate-50);
}

.button.state-pressed.default .text-wrapper {
  color: var(--purplepurple-700);
}

.button.text.state-default .text-wrapper {
  color: var(--purplepurple-500);
}

.button.link.large .text-wrapper {
  font-size: 16px;
  line-height: 24px;
}

.button.state-default.dashed .text-wrapper {
  color: var(--purplepurple-500);
}

.button.state-hover.link .text-wrapper {
  color: var(--purplepurple-500);
}

.button.text.state-hover .text-wrapper {
  color: var(--purplepurple-500);
}

.button.dashed.large .text-wrapper {
  font-family: var(--regular-16px-24px-font-family);
  font-size: var(--regular-16px-24px-font-size);
  font-style: var(--regular-16px-24px-font-style);
  font-weight: var(--regular-16px-24px-font-weight);
  letter-spacing: var(--regular-16px-24px-letter-spacing);
  line-height: var(--regular-16px-24px-line-height);
}

.button.state-hover.dashed .text-wrapper {
  color: var(--purplepurple-500);
}

.button.small.primary .text-wrapper {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.state-focused.dashed .text-wrapper {
  color: var(--purplepurple-600);
}

.button.state-default.link .text-wrapper {
  color: var(--purplepurple-400);
}

.button.default.default .text-wrapper {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.default.dashed .text-wrapper {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.state-focused.default .text-wrapper {
  color: var(--purplepurple-600);
}

.button.text.large .text-wrapper {
  font-family: var(--regular-16px-24px-font-family);
  font-size: var(--regular-16px-24px-font-size);
  font-style: var(--regular-16px-24px-font-style);
  font-weight: var(--regular-16px-24px-font-weight);
  letter-spacing: var(--regular-16px-24px-letter-spacing);
  line-height: var(--regular-16px-24px-line-height);
}

.button.default.small .text-wrapper {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.large .div {
  font-family: var(--regular-16px-24px-font-family);
  font-size: var(--regular-16px-24px-font-size);
  font-style: var(--regular-16px-24px-font-style);
  font-weight: var(--regular-16px-24px-font-weight);
  letter-spacing: var(--regular-16px-24px-letter-spacing);
  line-height: var(--regular-16px-24px-line-height);
}

.button.default .div {
  color: var(--slateslate-800);
}

.button.small .div {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.text .div {
  color: var(--slateslate-800);
}

.button.default .div {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.button.primary .div {
  color: var(--slateslate-50);
}

.button.dashed .div {
  color: var(--slateslate-800);
}

.button.link .div {
  color: var(--purplepurple-400);
}
