/* App.css */
:root {
  --blueberryblueberry-100: rgba(189, 206, 255, 1);
  --blueberryblueberry-200: rgba(158, 182, 255, 1);
  --blueberryblueberry-300: rgba(133, 161, 255, 1);
  --blueberryblueberry-400: rgba(97, 131, 255, 1);
  --blueberryblueberry-50: rgba(224, 233, 255, 1);
  --blueberryblueberry-500: rgba(67, 94, 219, 1);
  --blueberryblueberry-600: rgba(47, 70, 182, 1);
  --blueberryblueberry-700: rgba(30, 48, 148, 1);
  --blueberryblueberry-800: rgba(18, 30, 120, 1);
  --blueberryblueberry-900: rgba(14, 24, 98, 1);
  --body-text-m-alt-font-family: "Inter-ExtraLight", Helvetica;
  --body-text-m-alt-font-size: 16px;
  --body-text-m-alt-font-style: normal;
  --body-text-m-alt-font-weight: 200;
  --body-text-m-alt-letter-spacing: 0px;
  --body-text-m-alt-line-height: 24px;
  --body-text-m-font-family: "HeadingNow Trial-52Light", Helvetica;
  --body-text-m-font-size: 16px;
  --body-text-m-font-style: normal;
  --body-text-m-font-weight: 300;
  --body-text-m-letter-spacing: 0px;
  --body-text-m-line-height: 24px;
  --body-text-s-alt-font-family: "Inter-ExtraLight", Helvetica;
  --body-text-s-alt-font-size: 16px;
  --body-text-s-alt-font-style: normal;
  --body-text-s-alt-font-weight: 200;
  --body-text-s-alt-letter-spacing: 0px;
  --body-text-s-alt-line-height: 24px;
  --body-text-s-font-family: "HeadingNow Trial-52Light", Helvetica;
  --body-text-s-font-size: 16px;
  --body-text-s-font-style: normal;
  --body-text-s-font-weight: 300;
  --body-text-s-letter-spacing: 0px;
  --body-text-s-line-height: 24px;
  --carrotcarrot-100: rgba(255, 229, 173, 1);
  --carrotcarrot-200: rgba(255, 208, 128, 1);
  --carrotcarrot-300: rgba(255, 189, 97, 1);
  --carrotcarrot-400: rgba(255, 161, 46, 1);
  --carrotcarrot-50: rgba(255, 243, 214, 1);
  --carrotcarrot-500: rgba(217, 125, 32, 1);
  --carrotcarrot-600: rgba(182, 94, 22, 1);
  --carrotcarrot-700: rgba(149, 70, 14, 1);
  --carrotcarrot-800: rgba(120, 49, 8, 1);
  --carrotcarrot-900: rgba(96, 39, 6, 1);
  --CTA-m-font-family: "HeadingNow Trial-55Medium", Helvetica;
  --CTA-m-font-size: 14px;
  --CTA-m-font-style: normal;
  --CTA-m-font-weight: 500;
  --CTA-m-letter-spacing: 1.5px;
  --CTA-m-line-height: 16px;
  --CTA-s-font-family: "HeadingNow Trial-55Medium", Helvetica;
  --CTA-s-font-size: 14px;
  --CTA-s-font-style: normal;
  --CTA-s-font-weight: 500;
  --CTA-s-letter-spacing: 1.5px;
  --CTA-s-line-height: 16px;
  --display-l-font-family: "HeadingNow Trial-57Extrabold", Helvetica;
  --display-l-font-size: 48px;
  --display-l-font-style: normal;
  --display-l-font-weight: 400;
  --display-l-letter-spacing: -1px;
  --display-l-line-height: 56px;
  --display-XL-font-family: "HeadingNow Trial-57Extrabold", Helvetica;
  --display-XL-font-size: 80px;
  --display-XL-font-style: normal;
  --display-XL-font-weight: 400;
  --display-XL-letter-spacing: -2px;
  --display-XL-line-height: 88px;
  --fuchsiafuchsia-100: rgba(254, 153, 227, 1);
  --fuchsiafuchsia-200: rgba(254, 102, 227, 1);
  --fuchsiafuchsia-300: rgba(254, 64, 236, 1);
  --fuchsiafuchsia-400: rgba(254, 1, 252, 1);
  --fuchsiafuchsia-50: rgba(254, 204, 236, 1);
  --fuchsiafuchsia-500: rgba(201, 0, 218, 1);
  --fuchsiafuchsia-600: rgba(152, 0, 182, 1);
  --fuchsiafuchsia-700: rgba(110, 0, 147, 1);
  --fuchsiafuchsia-800: rgba(80, 0, 121, 1);
  --fuchsiafuchsia-900: rgba(65, 0, 97, 1);
  --grassgrass-100: rgba(193, 246, 172, 1);
  --grassgrass-200: rgba(149, 227, 130, 1);
  --grassgrass-300: rgba(101, 200, 91, 1);
  --grassgrass-400: rgba(47, 164, 45, 1);
  --grassgrass-50: rgba(227, 250, 214, 1);
  --grassgrass-500: rgba(32, 141, 39, 1);
  --grassgrass-600: rgba(21, 116, 36, 1);
  --grassgrass-700: rgba(14, 93, 32, 1);
  --grassgrass-800: rgba(8, 79, 30, 1);
  --grassgrass-900: rgba(7, 65, 25, 1);
  --graygray-100: rgba(242, 242, 243, 1);
  --graygray-200: rgba(207, 207, 211, 1);
  --graygray-300: rgba(160, 160, 167, 1);
  --graygray-400: rgba(113, 113, 122, 1);
  --graygray-50: rgba(245, 245, 245, 1);
  --graygray-500: rgba(69, 69, 74, 1);
  --graygray-600: rgba(24, 24, 27, 1);
  --graygray-700: rgba(15, 15, 16, 1);
  --graygray-800: rgba(20, 20, 21, 1);
  --graygray-900: rgba(10, 10, 11, 1);
  --heading-l-font-family: "HeadingNow Trial-57Extrabold", Helvetica;
  --heading-l-font-size: 32px;
  --heading-l-font-style: normal;
  --heading-l-font-weight: 400;
  --heading-l-letter-spacing: -0.5px;
  --heading-l-line-height: 40px;
  --heading-m-font-family: "HeadingNow Trial-57Extrabold", Helvetica;
  --heading-m-font-size: 24px;
  --heading-m-font-style: normal;
  --heading-m-font-weight: 400;
  --heading-m-letter-spacing: -0.25px;
  --heading-m-line-height: 32px;
  --heading-s-font-family: "HeadingNow Trial-57Extrabold", Helvetica;
  --heading-s-font-size: 18px;
  --heading-s-font-style: normal;
  --heading-s-font-weight: 400;
  --heading-s-letter-spacing: -0.25px;
  --heading-s-line-height: 26px;
  --label-l-font-family: "HeadingNow Trial-57Extrabold", Helvetica;
  --label-l-font-size: 14px;
  --label-l-font-style: normal;
  --label-l-font-weight: 400;
  --label-l-letter-spacing: 1px;
  --label-l-line-height: 16px;
  --label-m-font-family: "HeadingNow Trial-57Extrabold", Helvetica;
  --label-m-font-size: 12px;
  --label-m-font-style: normal;
  --label-m-font-weight: 400;
  --label-m-letter-spacing: 1px;
  --label-m-line-height: 16px;
  --label-s-font-family: "HeadingNow Trial-57Extrabold", Helvetica;
  --label-s-font-size: 10px;
  --label-s-font-style: normal;
  --label-s-font-weight: 400;
  --label-s-letter-spacing: 0.25px;
  --label-s-line-height: 14px;
  --orangeorange-100: rgba(255, 207, 168, 1);
  --orangeorange-200: rgba(255, 173, 122, 1);
  --orangeorange-300: rgba(255, 137, 87, 1);
  --orangeorange-400: rgba(255, 87, 36, 1);
  --orangeorange-50: rgba(255, 234, 209, 1);
  --orangeorange-500: rgba(220, 57, 24, 1);
  --orangeorange-600: rgba(182, 33, 17, 1);
  --orangeorange-700: rgba(148, 15, 10, 1);
  --orangeorange-800: rgba(122, 6, 12, 1);
  --orangeorange-900: rgba(97, 5, 10, 1);
  --pastel-greenpastel-green-100: rgba(161, 247, 167, 1);
  --pastel-greenpastel-green-200: rgba(114, 233, 134, 1);
  --pastel-greenpastel-green-300: rgba(78, 212, 114, 1);
  --pastel-greenpastel-green-400: rgba(28, 186, 86, 1);
  --pastel-greenpastel-green-50: rgba(210, 251, 208, 1);
  --pastel-greenpastel-green-500: rgba(20, 159, 82, 1);
  --pastel-greenpastel-green-600: rgba(14, 134, 82, 1);
  --pastel-greenpastel-green-700: rgba(8, 104, 72, 1);
  --pastel-greenpastel-green-800: rgba(5, 87, 66, 1);
  --pastel-greenpastel-green-900: rgba(4, 72, 55, 1);
  --persian-greenpersian-green-100: rgba(153, 245, 211, 1);
  --persian-greenpersian-green-200: rgba(96, 226, 191, 1);
  --persian-greenpersian-green-300: rgba(55, 200, 174, 1);
  --persian-greenpersian-green-400: rgba(6, 162, 147, 1);
  --persian-greenpersian-green-50: rgba(204, 250, 229, 1);
  --persian-greenpersian-green-500: rgba(4, 139, 139, 1);
  --persian-greenpersian-green-600: rgba(3, 105, 114, 1);
  --persian-greenpersian-green-700: rgba(1, 76, 91, 1);
  --persian-greenpersian-green-800: rgba(1, 54, 75, 1);
  --persian-greenpersian-green-900: rgba(1, 44, 60, 1);
  --purplepurple-100: rgba(206, 196, 253, 1);
  --purplepurple-200: rgba(177, 164, 249, 1);
  --purplepurple-300: rgba(157, 144, 244, 1);
  --purplepurple-400: rgba(148, 113, 244, 1);
  --purplepurple-50: rgba(230, 225, 254, 1);
  --purplepurple-500: rgba(112, 65, 241, 1);
  --purplepurple-600: rgba(65, 53, 172, 1);
  --purplepurple-700: rgba(43, 34, 140, 1);
  --purplepurple-800: rgba(26, 20, 113, 1);
  --purplepurple-900: rgba(22, 5, 72, 1);
  --raspberryraspberry-100: rgba(254, 172, 173, 1);
  --raspberryraspberry-200: rgba(253, 131, 143, 1);
  --raspberryraspberry-300: rgba(251, 99, 130, 1);
  --raspberryraspberry-400: rgba(249, 49, 109, 1);
  --raspberryraspberry-50: rgba(254, 217, 213, 1);
  --raspberryraspberry-500: rgba(214, 35, 106, 1);
  --raspberryraspberry-600: rgba(179, 24, 101, 1);
  --raspberryraspberry-700: rgba(144, 15, 92, 1);
  --raspberryraspberry-800: rgba(119, 9, 85, 1);
  --raspberryraspberry-900: rgba(95, 7, 67, 1);
  --redred-100: rgba(253, 191, 170, 1);
  --redred-200: rgba(251, 147, 126, 1);
  --redred-300: rgba(248, 106, 93, 1);
  --redred-400: rgba(244, 47, 47, 1);
  --redred-50: rgba(254, 226, 210, 1);
  --redred-500: rgba(207, 32, 47, 1);
  --redred-600: rgba(177, 22, 50, 1);
  --redred-700: rgba(139, 14, 47, 1);
  --redred-800: rgba(119, 8, 47, 1);
  --redred-900: rgba(95, 7, 38, 1);
  --regular-14px-22px-font-family: "Roboto-Regular", Helvetica;
  --regular-14px-22px-font-size: 14px;
  --regular-14px-22px-font-style: normal;
  --regular-14px-22px-font-weight: 400;
  --regular-14px-22px-letter-spacing: 0px;
  --regular-14px-22px-line-height: 22px;
  --regular-16px-24px-font-family: "Roboto-Regular", Helvetica;
  --regular-16px-24px-font-size: 16px;
  --regular-16px-24px-font-style: normal;
  --regular-16px-24px-font-weight: 400;
  --regular-16px-24px-letter-spacing: 0px;
  --regular-16px-24px-line-height: 24px;

  --regular-12px-20px-font-family: "Roboto-Regular", Helvetica;
  --regular-12px-20px-font-size: 12px;
  --regular-12px-20px-font-style: normal;
  --regular-12px-20px-font-weight: 400;
  --regular-12px-20px-letter-spacing: 0px;
  --regular-12px-20px-line-height: 20px;
  --regular-10px-18px-font-family: "Roboto-Regular", Helvetica;
  --regular-10px-18px-font-size: 10px;
  --regular-10px-18px-font-style: normal;
  --regular-10px-18px-font-weight: 400;
  --regular-10px-18px-letter-spacing: 0px;
  --regular-10px-18px-line-height: 18px;

  --royal-blueroyal-blue-100: rgba(182, 204, 252, 1);
  --royal-blueroyal-blue-200: rgba(142, 173, 246, 1);
  --royal-blueroyal-blue-300: rgba(111, 146, 236, 1);
  --royal-blueroyal-blue-400: rgba(65, 105, 225, 1);
  --royal-blueroyal-blue-50: rgba(216, 230, 253, 1);
  --royal-blueroyal-blue-500: rgba(47, 81, 193, 1);
  --royal-blueroyal-blue-600: rgba(32, 58, 162, 1);
  --royal-blueroyal-blue-700: rgba(20, 38, 128, 1);
  --royal-blueroyal-blue-800: rgba(12, 27, 110, 1);
  --royal-blueroyal-blue-900: rgba(10, 22, 87, 1);
  --skysky-100: rgba(159, 245, 254, 1);
  --skysky-200: rgba(108, 230, 254, 1);
  --skysky-300: rgba(72, 212, 254, 1);
  --skysky-400: rgba(11, 181, 254, 1);
  --skysky-50: rgba(205, 253, 254, 1);
  --skysky-500: rgba(8, 140, 217, 1);
  --skysky-600: rgba(5, 106, 184, 1);
  --skysky-700: rgba(3, 74, 145, 1);
  --skysky-800: rgba(2, 53, 121, 1);
  --skysky-900: rgba(2, 42, 95, 1);
  --slateslate-100: rgba(225, 227, 234, 1);
  --slateslate-200: rgba(203, 208, 220, 1);
  --slateslate-300: rgba(179, 186, 203, 1);
  --slateslate-400: rgba(156, 164, 186, 1);
  --slateslate-50: rgba(243, 244, 247, 1);
  --slateslate-500: rgba(132, 142, 169, 1);
  --slateslate-600: rgba(98, 110, 141, 1);
  --slateslate-700: rgba(73, 82, 105, 1);
  --slateslate-800: rgba(48, 53, 70, 1);
  --slateslate-900: rgba(17, 22, 36, 1);
  --turqoiseturqoise-100: rgba(152, 251, 239, 1);
  --turqoiseturqoise-200: rgba(102, 245, 238, 1);
  --turqoiseturqoise-300: rgba(65, 230, 236, 1);
  --turqoiseturqoise-400: rgba(8, 197, 221, 1);
  --turqoiseturqoise-50: rgba(206, 253, 244, 1);
  --turqoiseturqoise-500: rgba(5, 156, 194, 1);
  --turqoiseturqoise-600: rgba(4, 115, 159, 1);
  --turqoiseturqoise-700: rgba(2, 86, 131, 1);
  --turqoiseturqoise-800: rgba(1, 61, 106, 1);
  --turqoiseturqoise-900: rgba(1, 49, 86, 1);
  --yellowyellow-100: rgba(254, 249, 195, 1);
  --yellowyellow-200: rgba(254, 240, 138, 1);
  --yellowyellow-300: rgba(253, 224, 71, 1);
  --yellowyellow-400: rgba(250, 204, 21, 1);
  --yellowyellow-50: rgba(254, 252, 232, 1);
  --yellowyellow-500: rgba(234, 179, 8, 1);
  --yellowyellow-600: rgba(202, 138, 4, 1);
  --yellowyellow-700: rgba(161, 98, 7, 1);
  --yellowyellow-800: rgba(133, 77, 14, 1);
  --yellowyellow-900: rgba(113, 63, 18, 1);
}


.content-container {
  margin-top: 50px;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

.col2 {
  flex: 50%;
}

.row1 {
  flex: 100%;
}

html {
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
  /*height:   auto ;*/
  /*min-height: 100vh*/; /* At least as tall as the viewport */
}

body {
  background-color: #F3F4F7;
}

html[data-theme="dark"] body {
  background-color: #111624;
}

.main-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 100vh;
}

main {
  display: flex;
  max-width: 1675px;
  width: 100%;
}

main > section {
  width: 100%;
}

.title-section {
  background-color: #F3F4F7;
  position: relative;
  display: flex;
  flex-direction: column;
}

html[data-theme="dark"] .title-section {
  background-color: #111624;
}

.title-section .title-content {
  padding: 0 140px;
  display: grid;
  flex: 1 1 auto;
}

.title-content > div {
  place-self: flex-end;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribui o espaço entre o logo e a navegação */
  background-color: #F3F4F7;
  padding-top: 60px;
  padding-left: 70px;
  padding-right: 50px;
  padding-bottom: 50px;
}

html[data-theme="dark"] .App-header {
  background-color: #111624;
}

.App-header .buttons-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.heading {
  /*height: 40px;*/
  /*position: relative;*/
  margin: 2% 2% 2% 2%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  width: 96%;
  /*width: 150px; */
  background: var(--purplepurple-900);
  background: linear-gradient(225deg, #140541, #18054d);
  box-shadow:  -2px 2px 2px #0f0331,
               2px -2px 2px #1d075f;
}

.landing-title {
  font-family: 'Heading', sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  line-height: 1;
  color: #1A1245;
  margin-right: -100px;
  margin-bottom: 0;
  text-wrap: balance;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

html[data-theme="dark"] .landing-title {
  color: #F3F4F7;
}

.arrow-wrapper {
  display: none;
  justify-content: center;
  margin-bottom: 40px;
}

.arrow-wrapper > a {
  display: block;
  position: relative;
  width: 44px;
  height: 44px;
}

.circle-outline, .arrow-1, .arrow-2 {
  position: absolute;
}

.circle-outline {
  stroke-dasharray: 135;
  stroke-dashoffset: 0;
  animation: circle-reveal 1s ease-in-out;
  transform: rotate(-90deg);
  transform-origin: center;
}

.arrow-1 {
  left: 50%;
  top: 40%;
  transform: translateX(-50%) translateY(-50%);
  animation: arrow-down 1.5s ease-in-out .5s infinite;
}

.arrow-2 {
  left: 50%;
  top: 60%;
  transform: translateX(-50%) translateY(-50%);
  animation: arrow-down 1.5s ease-in-out .5s infinite;
}

@keyframes arrow-down {
  0% { transform: translateX(-50%) translateY(-100%); }
  50% { transform: translateX(-50%) translateY(0%); }
  100% { transform: translateX(-50%) translateY(-100%); }
}

@keyframes circle-reveal {
  0% { stroke-dashoffset: 135; }
  100% { stroke-dashoffset: 0; }
}

.form-link {
  color: #192032;
}

html[data-theme="dark"] .form-link {
  color: #F3F4F7;
}

.form-link:hover {
  opacity: 0.8;
}

.form-footer {
  display: none;
  text-align: center;
}

.form-footer .landing-text {
  color: #F3F4F7;
}

.form-footer .landing-text:last-child  {
  margin-top: 2rem;
}

@media (max-height: 900px) {
  .landing-title {
    margin-top: 100px;
  }
}

@media (max-width: 1600px) {
  .landing-footer {
    margin-top: 200px !important;
  }
}

@media (max-width: 1450px) {
  .title-section .title-content {
    padding-right: 50px;
  }

  .landing-title {
    margin-right: 1px;
  }

  .landing-footer {
    margin-top: 130px !important;
  }
}

@media (max-height: 960px) and (min-width: 1001px) {
  .title-section .title-content {
    padding-bottom: 100px;
  }
}

@media (max-width: 1000px) {
  main {
    flex-direction: column;
  }

  .background-container-desktop {
    display: none !important;
  }

  .background-container-mobile {
    display: block !important;
  }

  .background {
    width: 100%;
    height: 100%;
  }

  .form-section {
    padding-top: 75px;
    padding-bottom: 100px;
  }

  form.form {
    padding: 0 50px !important;
  }

  .title-section .title-content {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .landing-subtitle {
    margin-bottom: 74px !important;
  }

  .title-content .landing-email {
    display: none;
  }

  .landing-link {
    color: #F3F4F7 !important;
  }

  .landing-icon .path {
    fill: #F3F4F7 !important;
  }

  .title-content > .landing-footer {
    display: none;
    flex-direction: row;
    gap: 40px !important;
    margin-top: 70px !important;
    margin-bottom: 50px;
  }

  .landing-footer > .landing-text:last-child {
    text-align: left !important;
  }

  .copyright {
    display: none;
  }

  .arrow-wrapper {
    display: none;
  }

  .form-footer {
    display: block;
  }
}

@media (max-width: 1360px) {
  .title-section .title-content {
    padding-left: 100px;
  }
}

@media (max-width: 1275px) {
    
}

@media (max-width: 1230px) {
  .title-section .title-content {
    padding-right: 60px;
  }
}

@media (max-width: 1093px) {
  footer.landing-footer {
    margin-top: 100px !important;
  }
}

@media (max-width: 1160px) {
  .landing-footer {
    margin-top: 160px !important;
  }
}

@media (max-width: 1147px) {
  .landing-footer {
    flex-direction: column-reverse;
    gap: 10px !important;
    margin-top: 130px !important;
  }

  .form {
    padding: 0 25px !important;
  }
}

@media (max-width: 1030px) {
  .title-section .title-content {
    padding-left: 80px;
  } 
}

@media (max-width: 770px) {
  .title-section .title-content {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media (max-width: 650px) {
  .title-content .landing-subtitle {
    font-size: 16px !important;
  }
}

@media (max-width: 630px) {
  .title-section .title-content {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}

@media (max-width: 610px) {
  .form-socials {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 550px) {
  .title-section .title-content {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .App-header {
    padding-left: 60px;
    padding-right: 60px;
  }

  form.form {
    padding: 0 25px !important;
  }
}

@media (max-width: 520px) {
  .title-section .title-content {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .App-header {
    padding-left: 50px;
    padding-right: 50px;
  }

  .form {
    margin: 0 50px !important;
  }
}

@media (max-width: 500px) {
  .title-section .title-content {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .title-content .landing-subtitle {
    margin-bottom: 50px !important;
  }

  .App-header {
    padding-left: 40px;
    padding-right: 40px;
  }

  .form-section {
    gap: 40px !important;
  }
  
  .form-section h2 {
    margin: 0 40px !important;
  }

  .form {
    margin: 0 40px !important;
  }

  .theme-button {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .title-section .title-content {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .App-header {
    padding-left: 30px;
    padding-right: 30px;
  }

  .form-section h2 {
    margin: 0 30px !important;
  }

  .form {
    margin: 0 30px !important;
  }
}

@media (max-width: 460px) {
  .title-section .title-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .App-header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .form-section h2 {
    margin: 0 20px !important;
  }

  .form {
    margin: 0 20px !important;
  }
}

@media (max-width: 320px) {
  .App-header img {
    width: 180px;
  }
}

@media (max-width: 300px) {
  .App-header img {
    width: 160px;
  }
}


@media (max-width: 280px) {
  .App-header img {
    width: 140px;
  }
}

@media (max-width: 260px) {
  .App-header {
    padding-right: 0 !important;
  }
}

.landing-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  color: #7041F1;
  margin-top: 40px;
  margin-bottom: 100px;
  line-height: 1.5;
}

html[data-theme="dark"] .landing-subtitle {
  color: #9471F4;
}

.landing-email {
  margin-top: 100px;
  line-height: 1.5;
}

.landing-text {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #2B228C;
  margin: 0;
}

html[data-theme="dark"] .landing-text {
  color: #F3F4F7;
}

.no-break {
  white-space: nowrap;
}

.landing-link {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #7041F1;
  text-decoration: underline;
  text-underline-offset: 4px;
}

html[data-theme="dark"] .landing-link {
  color: #9471F4;
}

.landing-discord {
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
}

.landing-discord > span {
  margin-left: 4px;
}

.landing-footer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 180px;
  gap: 40px;
}

.landing-footer > .landing-text:last-child {
  text-align: right;
}

.landing-icon .path {
  fill: #7041F1;
}

html[data-theme="dark"] .landing-icon .path {
  fill: #9471F4;
}

.background-container-mobile {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  display: none;
}

.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  position: relative;
}

.form-section > h2 {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #F3F4F7;
  text-align: center;
  max-width: 402px;
  line-height: 1.5;
  margin: 0;
}

.form {
  background-color: #F3F4F7;
  padding: 0 50px;
  margin: 0 75px;
}

html[data-theme="dark"] .form {
  background-color: #111624;
}
.form-socials {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-about {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.checkbox input {
  accent-color: #7041F1;
}

html[data-theme="dark"] .checkbox input {
  accent-color: #9471F4;
}

.checkbox label {
  font-family: 'Inter', sans-serif;
  font-size: 0.9rem;
  color: #192032;
}

.checkbox:hover label {
  opacity: 0.8;
}

html[data-theme="dark"] .checkbox label {
  color: #F3F4F7;
}

.buttons-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.perguntas-jogador-profissional > .first-questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.perguntas-jogador-profissional > .heading3_br,
.perguntas-streamer-influenciador > .heading3_br:not(:first-child),
.perguntas-tecnico-coach > .heading3_br:not(:first-child),
.perguntas-analista-jogo > .heading3_br:not(:first-child),
.perguntas-comentarista-host > .heading3_br:not(:first-child) {
  margin-top: 20px;
}

.heading2 .text-wrapper2 {
  color: #2B228C;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  margin-top: 32px;
  margin-bottom: 24px;
}

html[data-theme="dark"] .heading2 .text-wrapper2 {
  color: #F3F4F7;
}

@font-face {
  font-family: 'Heading';
  src: local('Heading Now Trial 57 Extrabold'), url(./fonts/HeadingNowTrial57Extrabold.ttf) format('opentype');
}

@font-face {
    font-family: "Inter-ExtraLight";
    src: url("https://db.onlinewebfonts.com/t/7a850f9dfeb24ecb5164424ed43c0c6c.eot");
    src: url("https://db.onlinewebfonts.com/t/7a850f9dfeb24ecb5164424ed43c0c6c.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/7a850f9dfeb24ecb5164424ed43c0c6c.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/7a850f9dfeb24ecb5164424ed43c0c6c.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/7a850f9dfeb24ecb5164424ed43c0c6c.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/7a850f9dfeb24ecb5164424ed43c0c6c.svg#Inter ExtraLight")format("svg");
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("https://db.onlinewebfonts.com/t/03613b6e684c3d8421fc15ef7e0214b5.eot");
    src: url("https://db.onlinewebfonts.com/t/03613b6e684c3d8421fc15ef7e0214b5.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/03613b6e684c3d8421fc15ef7e0214b5.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/03613b6e684c3d8421fc15ef7e0214b5.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/03613b6e684c3d8421fc15ef7e0214b5.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/03613b6e684c3d8421fc15ef7e0214b5.svg#Roboto Regular")format("svg");
}

.center-button {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 35px;
  margin-top: 30px;
}

.heading .text-wrapper {
  color: white;
  font-family: "Heading";
  font-size: 36px;
  font-weight: 400;
  margin-left: 20px;
  letter-spacing: -1px;
  line-height: 56px;
  white-space: nowrap;

}

.heading3_br {

  margin-top: 10px;
  margin-bottom: 10px;
}


.text-wrapper3 {
  color: #2B228C;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  max-width: 470px;
}

html[data-theme="dark"] .text-wrapper3 {
  color: #F3F4F7;
}


.App-logo {
  width: 20%;
  min-height: 50px;
  min-width: 100px;
}

html[data-theme="dark"] .logo,
html[data-theme="light"] .logoWhite {
  display: none;
}

.theme-button {
  display: block;
  background-color: #E6E1FE;
  border: 0;
  cursor: pointer;
  padding: .75rem;
  border-radius: .125rem;
  height: 2.75rem;
}

.theme-button:hover {
  background-color: #CEC4FD;
}

html[data-theme="dark"] .theme-button {
  background-color: #192032;
}

html[data-theme="dark"] .theme-button:hover {
  background-color: #2D3750;
}

.theme-button svg {
  width: 1.25rem;
  height: 1.25rem;
}

.theme-button svg path {
  stroke: #7041F1;
}

html[data-theme="dark"] .theme-button svg path {
  stroke: #E6E1FE;
}

.nav-links {
  display: flex;
  align-items: center;
  /*justify-content: center; /* Espaça os links de navegação uniformemente */
  width: 100%; /* Faz com que a navegação ocupe o espaço disponível */
  /*max-width: 100vh; /* Ajuste conforme necessário para a largura máxima dos links */
}

.nav-link {
  color: white;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  justify-content: center;
  align-content: center;
  width:20%;
  font-size: 16px;
  margin-right: 6px;
  margin-left:6px;
  padding: 0 10px; /* Adiciona um pouco de espaço horizontal entre os links */
  font-family: "Heading";
  font-size: 12px;
  min-height: 50px;
  box-shadow:  1px -1px 5px #0f0331,
             -1px 1px 5px #1d075f;
  transition: .2s;
}

.nav-link:hover {
  box-shadow:  -7px 7px 0px #0f0331,
             7px -7px 0px #1d075f;
}

.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  background: linear-gradient(135deg, #3a2d5b 30%, #1c1c2b 100%);
  height: auto;   
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px; /* Adiciona um pouco de espaço no topo */
}

.icon_solid_white .path{
  fill: white !important;
}

.icon_solid_blue .path{
  fill: var(--purplepurple-100) !important;
}

.icon_solid_purple .path{
  fill: #7041F1 !important;
}

input::placeholder {
  color: transparent;
}

/* Placeholder text becomes visible when the input is focused */
input:focus::placeholder {
  color: #c7c7c7; /* Use a light color for visibility */
}

select::placeholder {
  color: transparent;
}

/* Placeholder text becomes visible when the input is focused */
select:focus::placeholder {
  color: #c7c7c7; /* Use a light color for visibility */
}

.button {
  background: #564f8a;
  padding: 10px;
  margin: 20px 0; /* Aumento da margem para mais espaço */
  border-radius: 10px; /* Borda mais arredondada para o estilo neomorfista */
  border: none;
  color: white;
  cursor: pointer;
  box-shadow: 4px 4px 8px #bebebe, -4px -4px 8px #ffffff; /* Sombra para efeito neomorfista */
}

.title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.typing-effect {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #3a2d5b; /* A mesma cor roxa do programa */
  font-size: 1.5rem;
  position: relative;
}

.typing-effect::after {
  content: '_';
  position: absolute;
  right: -2px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.typing-effect-text {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #3a2d5b; /* A mesma cor roxa do programa */
  font-size: 20px; /* Tamanho intermediário entre texto e título */
  position: relative;
}

.typing-effect-text::after {
  content: '|';
  position: absolute;
  right: -2px;
  animation: blink 1s infinite;
}

.form-container-one {
  display: flex;
  position: relative; /* Keep the element in the normal document flow */
  align-content: center;
  justify-content: center;
  width: 100%; /* Full width */
  height: auto;
  /*background-image: url('./luegoo2.png');
  background-repeat: repeat-y; /* Repeats the background image vertically */
  background-attachment: scroll; /* The background scrolls with the document */
  background-size: calc(max(700px, 50%)); /* Scales the background image */
  /*background: var(--purplepurple-900);;*/
  /*z-index: -1; /* Behind other content */
  min-height: 100vh;
}

.background-container-desktop {
  display: flex;
  position: absolute;
  width: 100%; /* Full width */
  min-height: 100vh; /* Minimum height to cover the full viewport height */
  height: 100%; /* Let the height be automatic to cover all content */
  z-index: -2; /* Behind other content */
}

.background-container-desktop > div {
  width: 100%;
}

.background {
  background-image: url('./images/landing_background.png');
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-size: cover;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2B228C;
  z-index: 1000; /* High z-index to overlay on top */
}

.popup-content {
  background-color: #F3F4F7;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 1001;
  font-family: 'Inter', sans-serif;
}

html[data-theme="dark"] .popup-content {
  background-color: #111624;
}

html[data-theme="dark"] .popup-content p {
  color: #F3F4F7;
}

.popup-content a {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #7041F1;
  text-decoration: underline;
  text-underline-offset: 4px;
}

html[data-theme="dark"] .popup-content a {
  color: #9471F4;
}

.popup-content .center-button {
  margin-bottom: 10px;
}
