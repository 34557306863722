.input-basic {
  align-items: center;
  border: 1px solid #9CA4BA;
  border-radius: 2px;
  display: flex;
  gap: 4px;
  position: relative;
}

html[data-theme="dark"] .input-basic {
  border: 1px solid #2D3750;
}

html[data-theme="dark"] .input-basic input {
  color: #F3F4F7;
}

.input-basic .prefix {
  color: #495269;
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

html[data-theme="dark"] .input-basic .prefix {
  color: #CBD0DC;
}

.input-basic .icon-outlined-user {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.input-basic .icon-outlined-user-4 {
  height: 18px !important;
  position: relative !important;
  width: 18px !important;
}

.input-basic .input-placeholder {
  flex: 50%;
}

.input-basic .suffix {
  color: var(--slateslate-700);
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.input-basic.large {
  padding: 8px 12px;
}

.input-basic.disabled {
  background-color: var(--slateslate-300);
  border-color: var(--slateslate-400);
}

.input-basic.small {
  padding: 1px 8px;
}

.input-basic.hover {
  background-color: var(--slateslate-50);
  border-color: var(--purplepurple-500);
}

.input-basic.default {
  padding: 8px 12px;
}

.input-basic.typing {
  background-color: var(--slateslate-50);
  border-color: var(--purplepurple-500);
  box-shadow: 0px 0px 4px #1890ff80;
}

.input-basic.filled {
  background-color: var(--slateslate-50);
  border-color: var(--slateslate-400);
}

.input-basic.focused {
  background-color: var(--slateslate-50);
  border-color: var(--purplepurple-500);
  box-shadow: 0px 0px 4px #1890ff80;
}

.input-basic.default .prefix {
  margin-top: -1px;
}

.input-basic.small .prefix {
  margin-top: -1px;
}

.input-basic.large .input-placeholder {
  font-family: var(--regular-16px-24px-font-family);
  font-size: var(--regular-16px-24px-font-size);
  font-style: var(--regular-16px-24px-font-style);
  font-weight: var(--regular-16px-24px-font-weight);
  letter-spacing: var(--regular-16px-24px-letter-spacing);
  line-height: var(--regular-16px-24px-line-height);
}

.input-basic.disabled .input-placeholder {
  color: var(--slateslate-600);
}

.input-basic.small .input-placeholder {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.input-basic.hover .input-placeholder {
  color: var(--slateslate-600);
}

.input-basic.default .input-placeholder {
  font-family: var(--regular-14px-22px-font-family);
  font-size: var(--regular-14px-22px-font-size);
  font-style: var(--regular-14px-22px-font-style);
  font-weight: var(--regular-14px-22px-font-weight);
  letter-spacing: var(--regular-14px-22px-letter-spacing);
  line-height: var(--regular-14px-22px-line-height);
}

.input-basic.default .input-placeholder {
  color: var(--slateslate-600);
}

.input-basic.typing .input-placeholder {
  color: var(--purplepurple-500);
}

.input-basic.filled .input-placeholder {
  color: var(--purplepurple-500);
}

.input-basic.focused .input-placeholder {
  color: var(--slateslate-600);
}

.input-basic.default .suffix {
  margin-top: -1px;
}

.input-basic.small .suffix {
  margin-top: -1px;
}


.input-basic > input {
  border: 0;
  height: 20px;
  width: 100%;
  background-color: #F3F4F7;
}

html[data-theme="dark"] .input-basic > input {
  background-color: #111624;
}

html[data-theme="dark"] .input-basic > input:-webkit-autofill,
html[data-theme="dark"] .input-basic > input:-webkit-autofill:hover, 
html[data-theme="dark"] .input-basic > input:-webkit-autofill:focus, 
html[data-theme="dark"] .input-basic > input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #111624 inset;
  -webkit-text-fill-color: #F3F4F7;
}

html[data-theme="dark"] #ano_nacimento {
  color-scheme: dark;
}

.input-basic:focus-within {
  border-radius: 1px;
  outline: #7041F1 solid 2px;
}

.input-basic > input:focus, .input-basic > select:focus {
  outline: 0;
}

.input-basic > select {
  margin-left: 5px;
  width: 100%;
  border: 0;
  background-color: #F3F4F7;
}

html[data-theme="dark"] .input-basic > select {
  background-color: #111624;
  color: #F3F4F7;
}

.input-basic > div > svg {
  height: 16px;
  width: 16px;
}

html[data-theme="dark"] .input-basic > div > svg > path {
  stroke: #9471F4;
}

html[data-theme="dark"] .input-basic > svg > path {
  stroke: #9471F4;
}

.input-basic > textarea {
  outline: 0;
  border: 0;
  width: 100%;
  resize: none;
}