.flash-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #1a1245; /* Your specified dark background color */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000; /* Make sure it's above everything else */
  animation: fadeInOut 4s ease forwards; /* Added animation for the container */
}

.flash-logo {
  width: 150px; /* Adjust to the size of your logo */
  height: auto;
  animation: flash 4s ease-out;
}

@keyframes flash {
  0%, 25%, 75%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

/* New combined keyframes for fading in and out the entire container */
@keyframes fadeInOut {
  0% { opacity: 1; }
  75% { opacity: 1; } /* Container stays fully visible while logo flashes */
  100% {
    opacity: 0;
    visibility: hidden;
    display: none; /* Ensures that the container doesn't block interaction with the page */
  }
}

