.select-mult.default {
  display: flex;
  flex-direction: column;
}

.select-mult {
  align-items: center;
  border: 1px solid #9CA4BA;
  border-radius: 2px;
  display: flex;
  gap: 4px;
  position: relative;
  max-width: 470px;
}

.select-mult:focus-within {
  outline: #7041F1 solid 2px;
}

.select-mult select:focus {
  outline: 0;
}

.select-mult.default .select-style {
  padding: 8px 0;
  border-radius: 6px;
  background: transparent;
  border: none;
  box-shadow: none;
}

.select-mult.default .selected-item {
  background-color: #7041F1;
  display: flex;
  color: #FFF;
  border-radius: 3px;
  padding: 5px 6px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-right: 4px; /* Add space between items */
  margin-bottom: 4px; /* Add space between rows */
  max-width: 120px;
}

.select-mult.default .selected-item button {
  background: transparent;
  border: none;
  color: white; /* Adjust button color as needed */
  cursor: pointer;
  margin-left: 3px;
  font-family: var(--body-text-m-alt-font-family);
}

.bottom-row {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
  /* Rest of your styles for the bottom row */
}

.select-mult.default .selected-item .item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%; /* This width is relative to the parent's width */
  /* Rest of your text styles */
}

.close-button {
  width: 20%;
  text-align: right;
  border: none;
  background: none;
  color: inherit; /* X button inherits the color from .selected-item */
  cursor: pointer;
  /* Rest of your button styles */
}

.select-mult.default .top-row{
  width: 100%;
  left: 0;
  margin-left: 0;
}

.select-mult.default .top-row select {
  margin-top: 5px;
  margin-left: 10px;
  width: calc(100% - 20px);
  min-width: 20%;
}

.select-mult.default .top-row select option{
  color: black;
}
