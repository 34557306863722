.MuiPaper-root {
  background-color: #F3F4F7 !important;
}

html[data-theme="dark"] .MuiPaper-root {
  background-color: #192032 !important;
}

html[data-theme="dark"] .MuiPaper-root div,
html[data-theme="dark"] .MuiPaper-root span,
html[data-theme="dark"] .MuiPaper-root p,
html[data-theme="dark"] .MuiPaper-root label,
html[data-theme="dark"] .MuiPaper-root button {
  color: #F3F4F7 !important;
}

html[data-theme="dark"] .MuiSvgIcon-root {
  fill: #9471F4;
}

.Mui-disabled {
  opacity: .5;
}

.Mui-selected {
  background-color: #7041F1;
}

html[data-theme="dark"] .Mui-selected {
  background-color: #9471F4;
}
