@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.terms-page main {
  display: block;
  font-family: 'Roboto', sans-serif;
  padding-left: 70px;
  padding-right: 50px;
  padding-bottom: 100px;
  width: auto;
  color: #495269;
}

html[data-theme="dark"] .terms-page main {
  color: #F3F4F7;
}

.terms-page h2 {
  font-size: 20px;
  font-weight: 500;
}

.terms-page ol {
  margin-top: 40px;
  padding: 0;
  list-style-position: inside;
}

.terms-page ol > div > li {
  font-weight: 500;
  margin-top: 40px;
}

.terms-page ul {
  padding-left: 10px;
  list-style-type: disc;
}

.terms-page ul > li {
  margin-top: 10px;
}

.terms-page main > div:nth-child(2) {
  margin-top: 60px;
}

.terms-page main > p:last-child {
  margin-top: 40px;
}

.terms-page a {
  color: #7041F1;
}

.terms-page a:hover {
  opacity: 0.8;
}

html[data-theme="dark"] .terms-page a {
  color: #9471F4;
}

@media (max-width: 550px) {
  .terms-page main {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-width: 520px) {
  .terms-page main {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 500px) {
  .terms-page main {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 480px) {
  .terms-page main {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 460px) {
  .terms-page main {
    padding-left: 20px;
    padding-right: 20px;
  }
}