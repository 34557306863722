.language-select {
  position: relative;
}

.language-select button {
  border: 0;
  padding: .5rem;
  background-color: #e6e1fe;
  cursor: pointer;
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  height: 2.75rem;
  width: 7.25rem;
}

.language-select > button {
  justify-content: space-between;
}

.language-select button:hover {
  background-color: #CEC4FD;
}

html[data-theme="dark"] .language-select button {
  background-color: #192032;
}

html[data-theme="dark"] .language-select button:hover {
  background-color: #2D3750;
}

.language-select .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.language-select .dropdown button {
  width: 100%;
}

.language-select span {
  font-family: 'Inter', sans-serif;
  color: #192032;
  text-transform: uppercase;
}

html[data-theme="dark"] .language-select span {
  color: #F3F4F7;
}

.language-select button > svg {
  width: 1.5rem;
}

.language-select button .chevron svg {
  width: 1rem;
}

.open-dropdown {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center
}

.close-dropdown {
  animation: shrinkUp 300ms ease-in-out forwards;
  transform-origin: top center
}

@keyframes growDown {
  0% {
      transform: scaleY(0)
  }
  80% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(1)
  }
}

@keyframes shrinkUp {
  0% {
      transform: scaleY(1)
  }
  20% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(0)
  }
}

@media (max-width: 400px) {
  .language-select button {
    width: fit-content;
  }

  .language-select span {
    display: none;
  }
}